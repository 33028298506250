const typename = Symbol("typename");
export type Nominal<T, N extends string> = T & {readonly [typename]: N};
export function nominate<T, const N extends string>(_name: N, arg: T): Nominal<T, N> {
  return arg as Nominal<T, N>;
}
export type ApiDate = Nominal<string, "date">;
export type ApiDateTime = Nominal<string, "datetime">;
export type ConfluenceDocumentSourceLabels = {[category in DocumentCategory]?: ConfluenceLabel};
export type BTreeMap<K extends string | number, V> = {[key in K]?: V};
export type IObject = {readonly [key: string]: IValue};
export type IValue = null | boolean | number | string | readonly IValue[] | IObject;
export type GraphOperationEntityRef = string | number;
export type Uuid = Nominal<string, "uuid">;
export type UserId = Nominal<string, "userId">;
export type OwnerId = Nominal<string, "ownerId">;
export type QuestionId = Nominal<string, "questionId">;
export type SectionId = Nominal<string, "sectionId">;
export type QuestionnaireId = Nominal<string, "questionnaireId">;
export type AccountId = Nominal<string, "accountId">;
export type CounterpartyId = Nominal<string, "counterpartyId">;
export type DocumentId = Nominal<string, "documentId">;
export type FileId = Nominal<string, "fileId">;
export type StandaloneFileId = Nominal<string, "standaloneFileId">;
export type DocumentationId = Nominal<string, "documentationId">;
export type TeamId = Nominal<string, "teamId">;
export type RoleId = Nominal<string, "roleId">;
export type DealId = Nominal<string, "dealId">;
export type ExternalAuthorizationId = Nominal<string, "externalAuthorizationId">;
export type AssetId = Nominal<string, "assetId">;
export type ActivityStreamId = Nominal<string, "activityStreamId">;
export type BackgroundTaskId = Nominal<string, "backgroundTaskId">;
export type ApikeyId = Nominal<string, "apikeyId">;
export type FactTextId = Nominal<string, "factTextId">;
export type FactClusterId = Nominal<string, "factClusterId">;
export type ScopeId = Nominal<string, "scopeId">;
export type ScopeAxisId = Nominal<string, "scopeAxisId">;
export type EntityId = Nominal<string, "entityId">;
export type ContentId = Nominal<string, "contentId">;
export type ResolutionId = Nominal<string, "resolutionId">;
export type ErrorCatalogId = Nominal<string, "errorCatalogId">;
export type DocumentAccessRequestId = Nominal<string, "documentAccessRequestId">;
export type CrmConnectionId = Nominal<string, "crmConnectionId">;
export type DocumentSourceId = Nominal<string, "documentSourceId">;
export type EsignatureProviderId = Nominal<string, "esignatureProviderId">;
export type EsignatureTemplateId = Nominal<string, "esignatureTemplateId">;
export type NotificationChannelId = Nominal<string, "notificationChannelId">;
export type QuestionSourceId = Nominal<string, "questionSourceId">;
export type LibrarySectionId = Nominal<string, "librarySectionId">;
export type FactId = Nominal<string, "factId">;
export type CoreResponseId = Nominal<string, "coreResponseId">;
export type FrameworkId = Nominal<string, "frameworkId">;
export type FrameworkVersionId = Nominal<string, "frameworkVersionId">;
export type FrameworkProfileId = Nominal<string, "frameworkProfileId">;
export type ThirdPartyId = Nominal<string, "thirdPartyId">;
export type ThirdPartyStatusId = Nominal<string, "thirdPartyStatusId">;
export type AssessmentId = Nominal<string, "assessmentId">;
export type ControlId = Nominal<string, "controlId">;
export type ControlGroupId = Nominal<string, "controlGroupId">;
export type SourceId = Nominal<string, "sourceId">;
export type SourceChunkId = Nominal<string, "sourceChunkId">;
export type MailingListId = Nominal<string, "mailingListId">;
export type ShowcaseUpdateMessageId = Nominal<string, "showcaseUpdateMessageId">;
export type MailSubscriptionId = Nominal<string, "mailSubscriptionId">;
export type ThirdPartyTierId = Nominal<string, "thirdPartyTierId">;
export type ThirdPartyTagId = Nominal<string, "thirdPartyTagId">;
export type NotificationId = Nominal<string, "notificationId">;
export type NotificationChannelIdNEW = Nominal<string, "notificationChannelIdNEW">;
export type DataRoomId = Nominal<string, "dataRoomId">;
export type RiskId = Nominal<string, "riskId">;
export type RiskAreaId = Nominal<string, "riskAreaId">;

export type PromptModifiers = {[K in PromptModifierType]?: (PromptModifier & {type: `${K}`})["content"]};

// Everything below is generated by the rust/types build script
/*
 Generated by typeshare 1.11.0
*/

export enum AiFeature {
	QuestionAnalysis = "QuestionAnalysis",
	DocumentAnalysis = "DocumentAnalysis",
	AnswerGeneration = "AnswerGeneration",
	FactClustering = "FactClustering",
	ConflictDetection = "ConflictDetection",
}

export enum AiModel {
	Gpt4Turbo = "Gpt4Turbo",
	Gpt4o = "Gpt4o",
	Gpt4oMini = "Gpt4oMini",
}

export type AiModelConfig = Record<AiFeature, AiModel>;

export type Release = string | undefined;

export enum AccessScope {
	TrustCenter = "TrustCenter",
	Collect = "Collect",
}

export interface AccessToken {
	token: string;
	user_id: UserId;
	client_account_id: AccountId;
	affiliated_account_id?: AccountId;
	expiry: ApiDateTime;
	scope: AccessScope;
}

export enum AccountType {
	Live = "Live",
	Sandbox = "Sandbox",
}

export enum LoginMethod {
	UsernamePassword = "UsernamePassword",
	GoogleOAuth = "GoogleOAuth",
	MicrosoftOAuth = "MicrosoftOAuth",
}

export interface AccountAccessGrant {
	login_method: LoginMethod;
	require_2fa: boolean;
}

export enum AccountFeature {
	VendorToolkit = "VendorToolkit",
	TPRM = "TPRM",
	TrustCenter = "TrustCenter",
	Scopes = "Scopes",
	ExpressCredits = "ExpressCredits",
	CsvImport = "CsvImport",
	InstantAnswering = "InstantAnswering",
	ResponseWizard = "ResponseWizard",
	SpreadsheetImport = "SpreadsheetImport",
	Collect = "Collect",
}

export interface Account {
	account_id: AccountId;
	name: string;
	display_name: string;
	slug: string;
	type_: AccountType;
	access_restrictions?: AccountAccessGrant[];
	plan_features: AccountFeature[];
	enabled_features: AccountFeature[];
	prompt_modifiers: PromptModifiers;
	express_credits: number;
	invalidation_channel_name: string;
	facts_modified_at: ApiDateTime;
	facts_clustered_at: ApiDateTime;
	language_code: string;
	ai_model_config: AiModelConfig;
}

export interface AccountMin {
	account_id: AccountId;
	display_name: string;
	slug: string;
}

export interface RegisteredUser {
	user_id: UserId;
	name: string;
	primary_email: string;
	avatar_url?: string;
	registered_at: ApiDateTime;
	login_method?: LoginMethod;
	enabled_2fa: boolean;
}

export enum SystemRole {
	Admin = "Admin",
}

export interface RoleMin {
	role_id: RoleId;
	system_role?: SystemRole;
	name: string;
}

export interface AccountUser {
	user: RegisteredUser;
	roles: RoleMin[];
}

export interface UserMin {
	user_id: UserId;
	name?: string;
	primary_email: string;
	avatar_url?: string;
}

export interface Actioned {
	at: ApiDateTime;
	by?: UserMin;
}

export type ActivityPayload = 
	| { type: "Comment", content: CommentPayload }
	| { type: "QuestionCreated", content: QuestionCreatedPayload }
	| { type: "QuestionUpdated", content: QuestionUpdatedPayload }
	| { type: "DocumentUpdated", content: DocumentUpdatedPayload };

export type Mention = 
	| { type: "Owner", content?: Owner };

export interface File {
	file_id: FileId;
	uploaded_at: ApiDateTime;
	name: string;
	size: number;
	mime_type: string;
	system_wide: boolean;
}

export interface Activity {
	activity_stream_id: ActivityStreamId;
	activity_ordinal: number;
	occurred_at: ApiDateTime;
	user?: UserMin;
	payload: ActivityPayload;
	mentions: Mention[];
	files: File[];
}

export enum ActivityType {
	Comment = "Comment",
	QuestionCreated = "QuestionCreated",
	QuestionUpdated = "QuestionUpdated",
	DocumentUpdated = "DocumentUpdated",
}

export interface ActivitySummary {
	counts: Record<ActivityType, number>;
}

export interface ActivityStream {
	activity_stream_id: ActivityStreamId;
	created_at: ApiDateTime;
	channel_name: string;
	summary: ActivitySummary;
}

export type Condition = 
	| { type: "Literal", content: boolean };

export interface AnswerPart<T> {
	enabled: Condition;
	config: T;
}

export interface YesNoAnswerConfig {
}

export interface AnswerOption {
	value: string;
	label: string;
	allow_other_text: boolean;
}

export interface SelectAnswerConfig {
	options: AnswerOption[];
	max_selected?: number;
}

export interface TextAnswerConfig {
	word_count_limit?: number;
}

export interface FilesAnswerConfig {
	min_number?: number;
	max_numer?: number;
}

export interface AnswerParts {
	yes_no: AnswerPart<YesNoAnswerConfig>;
	select: AnswerPart<SelectAnswerConfig>;
	text: AnswerPart<TextAnswerConfig>;
	files: AnswerPart<FilesAnswerConfig>;
}

export type OwnerPayload = 
	| { type: "Team", content: TeamMin }
	| { type: "User", content: UserMin };

export interface Owner {
	owner_id: OwnerId;
	owner_payload: OwnerPayload;
}

export interface ScopeAxisMin {
	axis_id: ScopeAxisId;
	name: string;
	color: string;
}

export interface ScopeMin {
	scope_id: ScopeId;
	name: string;
	owner?: Owner;
	axis: ScopeAxisMin;
}

export interface CoreResponse {
	core_response_id: CoreResponseId;
	created_at: ApiDateTime;
	deleted?: Actioned;
	question_text: string;
	response_text: string;
	limited_to_scopes?: ScopeMin[];
}

export interface RelevantCoreResponse {
	core_response: CoreResponse;
	question_cosine_distance: number;
}

export interface Question {
	question_id: QuestionId;
	question_number?: string;
	text: string;
	guidance?: string;
	parts: AnswerParts;
	response_layers: BTreeMap<LayerType, ResponseLayer>;
	disable_analysis: boolean;
	prompt_modifiers: PromptModifiers;
	used_prompt_modifiers?: PromptModifiers;
	is_internal: boolean;
	deleted?: Actioned;
	relevant_core_responses: RelevantCoreResponse[];
}

export interface QuestionSourceConnector {
	connector_id: string;
	name: string;
	logo_url: string;
}

export interface QuestionSourceMin {
	question_source_id: QuestionSourceId;
	name: string;
	connector: QuestionSourceConnector;
}

export interface AdHocQuestion {
	question: Question;
	asker?: UserMin;
	question_source?: QuestionSourceMin;
	source_url?: string;
}

export interface AdHocQuestionTask {
	adhoc_question: AdHocQuestion;
}

export interface Apikey {
	apikey_id: ApikeyId;
	name: string;
	account_id: AccountId;
	apikey: string;
	created_at: ApiDateTime;
}

export interface ThirdPartyTierMin {
	tier_id: ThirdPartyTierId;
	title: string;
	color: string;
}

export interface ThirdPartyTagMin {
	tag_id: ThirdPartyTagId;
	title: string;
	color: string;
}

export interface ThirdPartyStatusMin {
	status_id: ThirdPartyStatusId;
	title: string;
	color: string;
}

export interface ThirdPartyMin {
	third_party_id: ThirdPartyId;
	name: string;
	tier: ThirdPartyTierMin;
	tags: ThirdPartyTagMin[];
	status: ThirdPartyStatusMin;
	business_owner?: Owner;
	risk_owner?: Owner;
	created_at: ApiDateTime;
	deleted?: Actioned;
	url: string;
	due_date?: ApiDateTime;
	score: number;
}

export interface FrameworkMin {
	framework_id: FrameworkId;
	name: string;
	is_custom: boolean;
	is_enabled: boolean;
	icon_file?: File;
}

export interface FrameworkVersionMin {
	framework_version_id: FrameworkVersionId;
	framework: FrameworkMin;
	created_at: ApiDateTime;
	version: string;
	specification: string;
	published_at?: ApiDateTime;
}

export interface AssessmentControl {
	control_id: ControlId;
	met?: boolean;
	explanation?: string;
}

export interface Assessment {
	assessment_id: AssessmentId;
	third_party: ThirdPartyMin;
	framework_version: FrameworkVersionMin;
	created_at: ApiDateTime;
	completed_at?: ApiDateTime;
	controls: Record<ControlId, AssessmentControl>;
}

export interface AssessmentMin {
	assessment_id: AssessmentId;
	third_party: ThirdPartyMin;
	framework_version: FrameworkVersionMin;
	created_at: ApiDateTime;
	completed_at?: ApiDateTime;
}

export enum AssetOwner {
	TrustCenter = "TrustCenter",
}

export interface Asset {
	asset_id: AssetId;
	title: string;
	owner?: AssetOwner;
	file: File;
}

export interface AssetMin {
	asset_id: AssetId;
	title: string;
}

export interface BackgroundTask {
	background_task_id: BackgroundTaskId;
	name: string;
	created_at: ApiDateTime;
	scheduled_for: ApiDateTime;
	progress: number;
	channel_name: string;
}

export enum LayerType {
	External = "External",
	Internal = "Internal",
	AI = "AI",
}

export type BulkQuestionAction = 
	| { type: "Assign", content?: OwnerId }
	| { type: "SetStatus", content: QuestionStatus }
	| { type: "SetDueDate", content?: ApiDateTime }
	| { type: "SetText", content: string }
	| { type: "Delete", content?: undefined };

export interface BulkQuestionUpdateItem {
	question_id: QuestionId;
	action: BulkQuestionAction;
}

export interface BulkQuestionUpdate {
	layer_type: LayerType;
	items: BulkQuestionUpdateItem[];
}

export interface CharacterConfig {
	lowercase: boolean;
	uppercase: boolean;
	number: boolean;
	symbol: boolean;
}

export interface FactTextMin {
	fact_text_id: FactTextId;
	created_at: ApiDateTime;
	content: string;
}

export interface ClusteredFact {
	fact: FactTextMin;
	centroid_distance: number;
}

export type RichTextElement = 
	| { type: "Text", content: TextElement }
	| { type: "Mention", content: MentionElement };

export interface RichText {
	elements: RichTextElement[];
}

export interface CommentPayload {
	message: RichText;
}

export interface ConfluenceSite {
	id: string;
	name: string;
	url: string;
	avatar_url: string;
}

export interface ConfluenceSpace {
	id: string;
	name: string;
}

export interface ConfluenceDocumentSourceConfig {
	site: ConfluenceSite;
	space?: ConfluenceSpace;
	labels: ConfluenceDocumentSourceLabels;
}

export interface ConfluenceLabel {
	id: string;
	name: string;
	prefix: string;
}

export interface ConsentArgs {
	external_redirect: string;
}

export interface ConsentResponse {
	client_account: AccountMin;
	scope: AccessScope;
}

export interface Control {
	control_id: ControlId;
	control_number?: string;
	title: string;
	description: string;
}

export interface ControlGroup {
	control_group_id: ControlGroupId;
	title: string;
	description: string;
	controls: Control[];
}

export interface CountResolutionsArgs {
	assigned_to_me?: boolean;
}

export interface Counterparty {
	counterparty_id: CounterpartyId;
	name: string;
	url: string;
	crm_connection_id?: CrmConnectionId;
	crm_last_modified?: ApiDateTime;
	uri?: string;
	language_code: string;
}

export interface CreateAccessToken {
	user_id: UserId;
	client_account_id: AccountId;
	affiliated_account_id?: AccountId;
	scope: AccessScope;
}

export type CreateMention = 
	| { type: "Owner", content: OwnerId };

export interface CreateActivity {
	user_id?: UserId;
	payload: ActivityPayload;
	mentions: CreateMention[];
	file_ids: FileId[];
}

export interface ResponseOption {
	value: string;
	other_text?: string;
}

export enum QuestionStatus {
	Automating = "Automating",
	Respond = "Respond",
	Review = "Review",
	Complete = "Complete",
}

export interface CreateResponseLayer {
	response_yes_no?: boolean;
	response_select?: ResponseOption[];
	response_text?: string;
	status: QuestionStatus;
	owner_id?: OwnerId;
	due_date?: ApiDateTime;
}

export interface CreateQuestion {
	question_number?: string;
	text: string;
	guidance?: string;
	parts?: AnswerParts;
	response_layer: CreateResponseLayer;
}

export interface CreateAdHocQuestion {
	question: CreateQuestion;
	asker_id?: UserId;
	question_source_id?: QuestionSourceId;
	source_url?: string;
}

export interface CreateAssessment {
	third_party_id: ThirdPartyId;
	framework_version_id: FrameworkVersionId;
}

export interface CreateAsset {
	title: string;
	file_id: FileId;
	owner?: AssetOwner;
}

export interface CreateComment {
	message: RichText;
	mentions: CreateMention[];
	file_ids: FileId[];
}

export interface CreateControl {
	control_number?: string;
	title: string;
	description: string;
}

export interface CreateControlGroup {
	title: string;
	description: string;
}

export interface CreateCoreResponse {
	question_text: string;
	response_text: string;
	limited_to_scope_ids?: ScopeId[];
}

export interface CreateCounterparty {
	name: string;
	url: string;
	language_code: string;
}

export type CrmConnectionConfig = 
	| { type: "Hubspot", content: HubspotCrmConnectionConfig };

export interface CreateCrmConnection {
	name: string;
	connector_id: string;
	external_authorization_id?: ExternalAuthorizationId;
	config: CrmConnectionConfig;
}

export enum DataRoomType {
	Global = "Global",
	InternalApplication = "InternalApplication",
	ExternalApplication = "ExternalApplication",
}

export interface CreateDataRoom {
	name: string;
	room_type: DataRoomType;
}

export enum DocumentCategory {
	Policy = "Policy",
	Certification = "Certification",
	Report = "Report",
	Other = "Other",
}

export type ReviewPeriod = 
	| { type: "Days", content: number }
	| { type: "None", content?: undefined };

export enum SharingClassification {
	Internal = "Internal",
	NDA = "NDA",
	External = "External",
}

export enum DocumentAuthority {
	File = "File",
	Markdown = "Markdown",
	FileAndMarkdown = "FileAndMarkdown",
	Template = "Template",
}

export type Config = 
	| { type: "Password", content: PasswordPolicyConfig }
	| { type: "None", content?: undefined };

export enum CertificationType {
	Crest = "Crest",
	CsaStar = "CsaStar",
	CyberEssentials = "CyberEssentials",
	Iso27001 = "Iso27001",
	Iso27017 = "Iso27017",
	Iso27018 = "Iso27018",
	Iso27701 = "Iso27701",
	Soc2 = "Soc2",
	Soc3 = "Soc3",
}

export interface CreateDocument {
	name: string;
	category: DocumentCategory;
	review_period: ReviewPeriod;
	owner_id: OwnerId;
	sharing_classification: SharingClassification;
	authority: DocumentAuthority;
	file_id?: FileId;
	markdown?: string;
	authoring_config?: Config;
	authoring_template?: string;
	limited_to_scope_ids?: ScopeId[];
	certification_type?: CertificationType;
	certification_date_granted?: ApiDateTime;
	certification_date_expires?: ApiDateTime;
	certification_auditor_url?: string;
	certification_audit_report?: DocumentId;
}

export type DocumentSourceConfig = 
	| { type: "Platformed", content?: undefined }
	| { type: "GDrive", content: GDriveDocumentSourceConfig }
	| { type: "Microsoft", content: MicrosoftDocumentSourceConfig }
	| { type: "Confluence", content: ConfluenceDocumentSourceConfig }
	| { type: "Notion", content: NotionDocumentSourceConfig }
	| { type: "Web", content: WebDocumentSourceConfig };

export interface CreateDocumentSource {
	name: string;
	connector_id: string;
	external_authorization_id?: ExternalAuthorizationId;
	config: DocumentSourceConfig;
	default_owner_id: OwnerId;
	default_sharing_classification: SharingClassification;
	default_limited_to_scope_ids?: ScopeId[];
}

export interface CreateDocumentSourceKind {
	file_id: FileId;
}

export enum EsignatureProviderConnectorType {
	Docusign = "docusign",
}

export type EsignatureProviderConfig = 
	| { type: "Docusign", content: DocusignEsignatureProviderConfig };

export interface CreateEsignatureProvider {
	name: string;
	connector_type: EsignatureProviderConnectorType;
	external_authorization_id?: ExternalAuthorizationId;
	external_account_id?: string;
	config: EsignatureProviderConfig;
}

export enum ExternalAuthorizationProvider {
	Google = "Google",
	Atlassian = "Atlassian",
	Hubspot = "Hubspot",
	Slack = "Slack",
	Microsoft = "Microsoft",
	Docusign = "Docusign",
	Notion = "Notion",
	Manual = "Manual",
}

export type CreateExternalAuthorizationPayload = 
	| { type: "Oauth", content: CreateExternalAuthorizationOAuth }
	| { type: "Raw", content: CreateExternalAuthorizationRaw };

export interface CreateExternalAuthorization {
	provider: ExternalAuthorizationProvider;
	purpose: string;
	payload: CreateExternalAuthorizationPayload;
}

export interface CreateExternalAuthorizationOAuth {
	code: string;
}

export interface CreateExternalAuthorizationRaw {
	value: string;
}

export interface CreateFramework {
	name: string;
	is_custom: boolean;
	icon_file_id?: FileId;
}

export interface CreateFrameworkProfile {
	name: string;
	room_types: DataRoomType[];
}

export interface CreateFrameworkVersion {
	version: string;
	specification: string;
}

export interface CreateLibrarySection {
	name: string;
	description: string;
	parent_scope_id?: ScopeId;
	parent_section_id?: LibrarySectionId;
}

export enum NotificationChannelConnectorType {
	Slack = "slack",
}

export interface NotificationChannelConnector {
	connector_type: NotificationChannelConnectorType;
	name: string;
	logo_url: string;
}

export type NotificationChannelConfig = 
	| { type: "Slack", content: SlackNotificationChannelConfig };

export interface CreateNotificationChannel {
	name: string;
	connector: NotificationChannelConnector;
	external_authorization_id?: ExternalAuthorizationId;
	config: NotificationChannelConfig;
}

export enum NotificationChannelTypeNEW {
	Slack = "Slack",
	Email = "Email",
	EmailDigest = "EmailDigest",
	InApp = "InApp",
}

export type NotificationChannelConfigNEW = 
	| { type: "Slack", content: SlackNotificationChannelConfigNew }
	| { type: "Email", content: EmailNotificationChannelConfig }
	| { type: "EmailDigest", content: EmailNotificationChannelConfig }
	| { type: "InApp", content: InAppNotificationChannelConfig };

export interface CreateNotificationChannelNEW {
	channel_type: NotificationChannelTypeNEW;
	config: NotificationChannelConfigNEW;
}

export interface CreateOrUpdateShowcaseUpdateMessage {
	title?: string;
	content?: string;
}

export type CreateDocumentationAttachment = 
	| { type: "Document", content: {
	document_id: DocumentId;
}}
	| { type: "StandaloneFile", content: {
	standalone_file_id: StandaloneFileId;
}};

export interface CreateQuestionDocumentation {
	attachment: CreateDocumentationAttachment;
}

export interface CreateQuestionInSection {
	section_id: SectionId;
	question: CreateQuestion;
}

export type QuestionSourceConfig = 
	| { type: "Slack", content: SlackQuestionSourceConfig };

export interface CreateQuestionSource {
	name: string;
	connector_id: string;
	external_authorization_id: ExternalAuthorizationId;
	config: QuestionSourceConfig;
}

export interface CreateSection {
	title: string;
	description: string;
	questions: CreateQuestion[];
}

export interface ScopeIdList {
	scope_ids: ScopeId[];
}

export interface ScopeMatrix {
	rows: ScopeIdList[];
}

export interface CreateQuestionnaire {
	counterparty_id: CounterpartyId;
	name: string;
	owner_id?: OwnerId;
	sections?: CreateSection[];
	due_date?: ApiDateTime;
	archived?: boolean;
	scope_matrix?: ScopeMatrix;
	is_internal?: boolean;
	prompt_modifiers?: PromptModifiers;
}

export interface CreateQuestionnaireDocumentation {
	attachment: CreateDocumentationAttachment;
	linked_to_questionnaire: boolean;
	linked_question_ids: QuestionId[];
}

export enum OAuthProvider {
	Google = "google",
	Microsoft = "microsoft",
}

export interface CreateRegisteredUser {
	name: string;
	email: string;
	password?: string;
	login_method?: LoginMethod;
	oauth_user_ids?: Record<OAuthProvider, string>;
	avatar_url?: string;
}

export interface CreateRiskContext {
	data_room_id: DataRoomId;
	risk_area_id: RiskAreaId;
}

export interface CreateRisk {
	title: string;
	description: string;
	owner_id?: UserId;
	contexts: CreateRiskContext[];
}

export interface CreateRiskArea {
	title: string;
}

export interface CreateScope {
	name: string;
	axis_id: ScopeAxisId;
}

export interface CreateScopeAxis {
	name: string;
	description?: string;
	color: string;
}

export type CreateSourceKind = 
	| { type: "Document", content: CreateDocumentSourceKind }
	| { type: "Questionnaire", content?: undefined };

export interface CreateSource {
	name: string;
	kind: CreateSourceKind;
}

export interface CreateStandaloneFile {
	file_id: FileId;
	sharing_classification: SharingClassification;
}

export interface CreateTeam {
	name: string;
	description?: string;
}

export interface CreateThirdParty {
	name: string;
	url: string;
	tier_id: ThirdPartyTierId;
	status_id?: ThirdPartyStatusId;
	tag_ids?: ThirdPartyTagId[];
	due_date?: ApiDateTime;
	business_owner_id?: OwnerId;
	risk_owner_id?: OwnerId;
}

export interface CreateThirdPartyStatus {
	title: string;
	color: string;
}

export interface CreateThirdPartyTag {
	title: string;
	color: string;
}

export interface CreateThirdPartyTier {
	title: string;
	color: string;
}

export interface CreateTrustCenterContent {
	title: string;
	content: string;
}

/**
 * Contains metadata only available on the initial call to authorize an OAuth token, such as an external
 * user ID or a workspace identifier.
 */
export interface CreatedAuthorizationMetadata {
	external_user_id?: string;
}

export type ExternalAuthorizationPayload = 
	| { type: "Oauth", content: ExternalAuthorizationOauth }
	| { type: "Raw", content: ExternalAuthorizationRaw };

export interface CreatedExternalAuthorization {
	external_authorization_id: ExternalAuthorizationId;
	user?: UserMin;
	provider: ExternalAuthorizationProvider;
	metadata: CreatedAuthorizationMetadata;
	purpose: string;
	payload: ExternalAuthorizationPayload;
	access_token_expiry?: ApiDateTime;
	refresh_at?: ApiDateTime;
}

export interface CrmAccountInfo {
	account_id: string;
}

export interface CrmConnector {
	connector_id: string;
	name: string;
	logo_url: string;
}

export interface ExternalAuthorizationMin {
	external_authorization_id: ExternalAuthorizationId;
	user?: UserMin;
	provider: ExternalAuthorizationProvider;
	purpose: string;
	access_token_expiry?: ApiDateTime;
	refresh_at?: ApiDateTime;
	refresh_attempts: number;
}

export interface CrmConnection {
	crm_connection_id: CrmConnectionId;
	name: string;
	connector: CrmConnector;
	config: CrmConnectionConfig;
	external_authorization: ExternalAuthorizationMin;
	sync?: BackgroundTask;
}

export interface CrmConnectionMin {
	crm_connection_id: CrmConnectionId;
	name: string;
	connector: CrmConnector;
	sync?: BackgroundTask;
}

export interface CrmCounterparty {
	crm_object_id: string;
	domain: string;
	name: string;
	uri: string;
	last_modified: ApiDateTime;
	archived: boolean;
	language_code?: string;
}

export interface CrmDeal {
	name: string;
	amount?: number;
	stage_key?: string;
	crm_counterparty_id: string;
	close_date?: ApiDateTime;
	uri: string;
	last_modified: ApiDateTime;
}

export enum CustomDomainType {
	TrustCenter = "TrustCenter",
	Collect = "Collect",
}

export interface CustomDomain {
	domain: string;
	account_id: AccountId;
	domain_type: CustomDomainType;
	redirect_to: boolean;
}

export interface DataRoomMin {
	data_room_id: DataRoomId;
	third_party: ThirdPartyMin;
	deleted?: Actioned;
	name: string;
	room_type: DataRoomType;
}

export interface Deal {
	deal_id: DealId;
	counterparty: Counterparty;
	crm_connection_id: CrmConnectionId;
	name: string;
	amount?: number;
	owner?: Owner;
	created_at: ApiDateTime;
	close_date?: ApiDateTime;
	uri: string;
}

export interface DeleteDocumentSourceArgs {
	retain_documents?: boolean;
}

export enum TrustCenterAccess {
	Allowed = "Allowed",
	GeneralGrant = "GeneralGrant",
	SpecificGrant = "SpecificGrant",
	Denied = "Denied",
}

export interface DocumentMin {
	document_id: DocumentId;
	name: string;
	category: DocumentCategory;
	created_at: ApiDateTime;
	last_update: Actioned;
	last_review?: ApiDateTime;
	next_review?: ApiDateTime;
	review_period: ReviewPeriod;
	sharing_classification: SharingClassification;
	owner?: Owner;
	authority: DocumentAuthority;
	file: File;
	trust_center_visibility: TrustCenterAccess;
	trust_center_readability: TrustCenterAccess;
	trust_center_esignature_template_id?: EsignatureTemplateId;
}

export interface Document {
	document_id: DocumentId;
	name: string;
	category: DocumentCategory;
	created_at: ApiDateTime;
	last_update: Actioned;
	last_analyzed?: ApiDateTime;
	last_review?: ApiDateTime;
	next_review?: ApiDateTime;
	review_period: ReviewPeriod;
	sharing_classification: SharingClassification;
	owner?: Owner;
	authority: DocumentAuthority;
	file: File;
	markdown: string;
	markdown_modified: boolean;
	extract?: BackgroundTask;
	authoring_template?: string;
	authoring_config?: Config;
	limited_to_scopes?: ScopeMin[];
	trust_center_visibility: TrustCenterAccess;
	trust_center_readability: TrustCenterAccess;
	trust_center_esignature_template_id?: EsignatureTemplateId;
	deleted?: Actioned;
	certification_type?: CertificationType;
	certification_date_granted?: ApiDateTime;
	certification_date_expires?: ApiDateTime;
	certification_auditor_url?: string;
	certification_audit_report?: DocumentMin;
	activity_stream: ActivityStream;
}

export interface DocumentAccessRequest {
	email: string;
	name: string;
	phone: string;
	point_of_contact: string;
	message: string;
	already_signed_nda?: boolean;
}

export interface DocumentExternalMin {
	document_id: DocumentId;
	name: string;
	category: DocumentCategory;
	last_update: ApiDateTime;
	is_readable: boolean;
	requires_esignature: boolean;
}

export interface DocumentExternal {
	document_id: DocumentId;
	name: string;
	category: DocumentCategory;
	last_update: ApiDateTime;
	is_readable: boolean;
	requires_esignature: boolean;
	certification_type?: CertificationType;
	certification_date_granted?: ApiDateTime;
	certification_date_expires?: ApiDateTime;
	certification_auditor_url?: string;
	certification_audit_report?: DocumentExternalMin;
}

export interface DocumentSourceConnector {
	connector_id: string;
	name: string;
	logo_url: string;
}

export interface ReportedErrorCatalogMin {
	catalog_id: ErrorCatalogId;
	created_at: ApiDateTime;
	num_errors: number;
}

export interface DocumentSource {
	document_source_id: DocumentSourceId;
	name: string;
	connector: DocumentSourceConnector;
	external_authorization?: ExternalAuthorizationMin;
	config: DocumentSourceConfig;
	num_documents: number;
	sync?: BackgroundTask;
	last_sync_at?: ApiDateTime;
	last_error_catalog?: ReportedErrorCatalogMin;
	default_owner: Owner;
	default_sharing_classification: SharingClassification;
	default_limited_to_scopes?: ScopeMin[];
}

export interface DocumentSourceKind {
	file: File;
	markdown?: string;
	extract?: BackgroundTask;
}

export interface DocumentSourceMin {
	document_source_id?: DocumentSourceId;
	name: string;
	connector: DocumentSourceConnector;
	num_documents: number;
	sync?: BackgroundTask;
	last_sync_at?: ApiDateTime;
	last_error_catalog?: ReportedErrorCatalogMin;
}

export interface DocumentSourceMinKind {
	file: File;
}

export type DocumentationAttachment = 
	| { type: "Document", content: DocumentMin }
	| { type: "StandaloneFile", content: StandaloneFile };

export interface QuestionDocumentation {
	question_id: QuestionId;
	linked_at: ApiDateTime;
	linked_by?: UserMin;
}

export interface Documentation {
	documentation_id: DocumentationId;
	attachment: DocumentationAttachment;
	linked_to_questionnaire: boolean;
	linked_questions: QuestionDocumentation[];
	created_at: ApiDateTime;
}

export interface DocusignEsignatureProviderConfig {
	account_name: string;
	base_uri: string;
}

export interface DownloadOptions {
	override_name?: string;
}

export interface EmailNotificationChannelConfig {
}

export interface EnableTwoFaTotp {
	secret: string;
	code: string;
}

export interface EsignatureProviderConnector {
	connector_type: EsignatureProviderConnectorType;
	name: string;
	logo_url: string;
}

export interface ExternalAuthorization {
	external_authorization_id: ExternalAuthorizationId;
	user?: UserMin;
	provider: ExternalAuthorizationProvider;
	purpose: string;
	payload: ExternalAuthorizationPayload;
	access_token_expiry?: ApiDateTime;
	refresh_at?: ApiDateTime;
	refresh_attempts: number;
}

export interface EsignatureProvider {
	esignature_provider_id: EsignatureProviderId;
	account_id: AccountId;
	name: string;
	config: EsignatureProviderConfig;
	connector: EsignatureProviderConnector;
	external_authorization?: ExternalAuthorization;
	external_account_id?: string;
	last_sync_at?: ApiDateTime;
}

export interface EsignatureProviderMin {
	esignature_provider_id: EsignatureProviderId;
	account_id: AccountId;
	name: string;
	config: EsignatureProviderConfig;
	connector: EsignatureProviderConnector;
	last_sync_at?: ApiDateTime;
}

export interface EsignatureTemplate {
	template_id: EsignatureTemplateId;
	external_template_id: string;
	name: string;
	provider: EsignatureProviderMin;
}

export interface ExceptionConfig {
	compromised_password_process: string;
	non_compliance: string;
}

export interface ExternalAuthorizationOauth {
	access_token: string;
}

export interface ExternalAuthorizationRaw {
	value: string;
}

export interface ExternalDocument {
	uri: string;
	last_modified: ApiDateTime;
}

export interface RegisteredUserMin {
	user_id: UserId;
	name: string;
	primary_email: string;
	avatar_url?: string;
}

export interface ExternalWhoAmIResponse {
	user: RegisteredUserMin;
	client_account: AccountMin;
	affiliated_account?: AccountMin;
	internal_mode: boolean;
}

export interface LibrarySectionWithoutScope {
	library_section_id: LibrarySectionId;
	name: string;
	description?: string;
	owner?: Owner;
	parent_section_id?: LibrarySectionId;
	deleted?: Actioned;
}

export interface ScopedLibrarySection {
	scope?: ScopeMin;
	library_section?: LibrarySectionWithoutScope;
}

export interface FactSourceDocument {
	document_id: DocumentId;
	document_name: string;
	document_revision: ApiDateTime;
	document_category: DocumentCategory;
	limited_to_scopes?: ScopeMin[];
	explanation_chunk: string;
}

export type FactSourceQuestionParent = 
	| { type: "Section", content: {
	questionnaire_id: QuestionnaireId;
	questionnaire_name: string;
	questionnaire_archived_at?: ApiDateTime;
	section_title: string;
	questionnaire_date: ApiDateTime;
}}
	| { type: "AdHoc", content: {
	question_date: ApiDateTime;
}};

export interface FactSourceQuestion {
	parent: FactSourceQuestionParent;
	question_id: QuestionId;
	question_number?: string;
	question_text: string;
	response_parts: AnswerParts;
	response_yes_no?: boolean;
	response_select?: ResponseOption[];
	response_text?: string;
	limited_to_scopes?: ScopeMin[];
}

export interface FactSourceEntity {
	entity_id: EntityId;
	limited_to_scopes?: ScopeMin[];
}

export enum ResolutionType {
	Contradiction = "Contradiction",
	Stale = "Stale",
	Recurrent = "Recurrent",
	Sensitive = "Sensitive",
}

export interface ResolutionMin {
	resolution_id: ResolutionId;
	scope?: ScopeMin;
	resolution_type: ResolutionType;
	num_involved_facts: number;
	reason: string;
	review_necessity: number;
	review_explanation: string;
	created_at: ApiDateTime;
	owner?: Owner;
	resolved?: Actioned;
	ignored?: Actioned;
	is_internal: boolean;
}

export interface Fact {
	fact_id: FactId;
	representative_text: FactTextMin;
	all_texts: FactTextMin[];
	library_section: ScopedLibrarySection;
	document_sources: FactSourceDocument[];
	question_sources: FactSourceQuestion[];
	subgraph_sources: FactSourceEntity[];
	resolutions: ResolutionMin[];
	created_at: ApiDateTime;
	disabled?: Actioned;
	deleted?: Actioned;
}

export interface FactCluster {
	cluster_id: FactClusterId;
	title: string;
	children?: FactCluster[];
	equivalence_count: number;
}

export interface FactClusterMin {
	cluster_id: FactClusterId;
	title: string;
}

export interface FactMin {
	fact_id: FactId;
	representative_text: FactTextMin;
	library_section: ScopedLibrarySection;
	created_at: ApiDateTime;
	disabled?: Actioned;
	deleted?: Actioned;
}

export interface ScopedLibrarySectionId {
	scope_id?: ScopeId;
	library_section_id?: LibrarySectionId;
}

export interface LibrarySectionFilter {
	scoped_id: ScopedLibrarySectionId;
	recursive: boolean;
}

export interface PaginationArgs {
	offset?: number;
	limit?: number;
}

export interface FactSearchArgs {
	search?: string;
	section_filter?: LibrarySectionFilter;
	pagination?: PaginationArgs;
}

export interface FilterAssessments {
	framework_id?: FrameworkId;
	framework_version_id?: FrameworkVersionId;
	third_party_id?: ThirdPartyId;
	completed?: boolean;
}

export interface RiskArea {
	risk_area_id: RiskAreaId;
	title: string;
}

export interface FrameworkProfile {
	framework_profile_id: FrameworkProfileId;
	framework_version: FrameworkVersionMin;
	created_at: ApiDateTime;
	name: string;
	room_types: DataRoomType[];
	control_groups: ControlGroup[];
	risk_areas: RiskArea[];
}

export interface FrameworkProfileMin {
	framework_profile_id: FrameworkProfileId;
	framework_version: FrameworkVersionMin;
	created_at: ApiDateTime;
	name: string;
	room_types: DataRoomType[];
}

export enum Environment {
	Development = "development",
	Test = "test",
	Staging = "staging",
	Production = "production",
}

export interface PublicCredentials {
	knock_public_key: string;
	knock_feed_id: string;
	sentry_dsn: string;
	fullstory_org: string;
	google_client_id: string;
	ms_client_id: string;
	atlassian_client_id: string;
	pusher_key: string;
	pusher_cluster: string;
	hubspot_client_id: string;
	slack_client_id: string;
	docusign_client_id: string;
	docusign_endpoint: string;
	notion_client_id: string;
}

export interface FrontendEnvironment {
	environment: Environment;
	release: Release;
	app_url: string;
	trust_url: string;
	credentials: PublicCredentials;
	custom_domain?: CustomDomain;
}

export interface GDriveDocumentSourceConfig {
	folder_id: string;
	folder_name: string;
	folder_icon_url: string;
}

export interface GenerateTotpResponse {
	qr_code: string;
	secret: string;
}

export interface GetGraphQuery {
	selected_properties?: string[];
	filter_properties?: string[];
	filter_entity_ids?: EntityId[];
	recursion_depth?: number;
}

export interface Rational {
	num: number;
	den: number;
}

export interface GettingStartedChecklist {
	uploaded_documents: Rational;
	uploaded_historical_questionnaires: Rational;
	invited_colleagues: Rational;
	uploaded_questionnaire: Rational;
}

export interface GraphEntity {
	id: EntityId;
	properties: Record<string, IValue>;
	link_ids: EntityId[];
}

export interface GraphOperationPropertyValueRelationship {
	parent_entity_ref: GraphOperationEntityRef;
	child_entity_ref: GraphOperationEntityRef;
}

export enum GraphComponentType {
	String = "String",
	Integer = "Integer",
	Boolean = "Boolean",
	Asset = "Asset",
	Relationship = "Relationship",
}

export interface PropertyConfig {
}

export interface GraphProperty {
	property_name: string;
	label?: string;
	component_type: GraphComponentType;
	config?: PropertyConfig;
	account_id?: AccountId;
}

export interface GraphPropertyValueRelationship {
	parent_entity_id: EntityId;
	child_entity_id: EntityId;
}

export interface HubspotCrmConnectionConfig {
	hubspot_account_id: string;
}

export interface IdentifiedSheetCell {
	row: number;
	col: number;
}

export interface IdentifiedSheetRange {
	start: IdentifiedSheetCell;
	end: IdentifiedSheetCell;
}

export interface IdentifiedSheetSection {
	section_title?: string;
	questions: IdentifiedSheetRange;
	column_headers?: IdentifiedSheetRange;
}

export interface ImportQuestions {
	questions?: CreateQuestionInSection[];
	sections?: CreateSection[];
	automate?: boolean;
}

export interface InAppNotificationChannelConfig {
}

export interface InviteUser {
	name: string;
	email: string;
}

export interface LibrarySectionMin {
	library_section_id: LibrarySectionId;
	name: string;
	description?: string;
	owner?: Owner;
	parent_scope?: ScopeMin;
	parent_section_id?: LibrarySectionId;
	deleted?: Actioned;
}

export interface LinkNotificationChannel {
	notification_channel_id: NotificationChannelId;
	external_user_id?: string;
}

export interface NotificationChannelMin {
	notification_channel_id: NotificationChannelId;
	account_id: AccountId;
	name: string;
	config: NotificationChannelConfig;
	connector: NotificationChannelConnector;
}

export interface LinkedNotificationChannelMin {
	notification_channel: NotificationChannelMin;
	external_user_id?: string;
}

export interface ListActivitiesArgs {
	since?: number;
	before?: number;
}

export interface ListAssets {
	owner?: AssetOwner;
}

export interface ListLibrarySectionFactsArgs {
	recurse?: boolean;
}

export interface ListResolutionsArgs {
	assigned_to_me?: boolean;
	limit?: number;
	offset?: number;
}

export interface ListScopeFactsArgs {
	recurse?: boolean;
}

export interface LoginCredentials {
	password: string;
	totp_code?: string;
}

export interface MailSubscription {
	mail_subscription_id: MailSubscriptionId;
	email: string;
	mailing_list_id: MailingListId;
	last_verification_attempt: ApiDateTime;
	verified_at?: ApiDateTime;
	subscribed_at?: ApiDateTime;
}

export interface SubscriberMetadata {
	name?: string;
	company?: string;
}

export interface Subscriber {
	mail_subscription_id: MailSubscriptionId;
	email: string;
	metadata: SubscriberMetadata;
	verified_at?: ApiDateTime;
	subscribed_at?: ApiDateTime;
}

export interface MailingList {
	subscribers: Subscriber[];
	active_count: number;
	inactive_count: number;
}

export interface PostmarkServerDetails {
	id?: number;
	name?: string;
}

export interface MailingListDetails {
	mailing_list_id: MailingListId;
	account_id: AccountId;
	postmark_server_details: PostmarkServerDetails;
}

export interface MentionElement {
	index: number;
}

export interface MicrosoftDocumentSourceConfig {
	drive_id: string;
	item_id: string;
	item_name: string;
}

export enum NotificationType {
	AddedToTeam = "AddedToTeam",
	Commented = "Commented",
	Mentioned = "Mentioned",
	QuestionAssigned = "QuestionAssigned",
	QuestionAutomating = "QuestionAutomating",
	QuestionNudge = "QuestionNudge",
	QuestionUpdated = "QuestionUpdated",
	QuestionnaireAssigned = "QuestionnaireAssigned",
	QuestionnaireNudge = "QuestionnaireNudge",
}

export type NotificationPayloadType = 
	| { type: "Team", content: TeamMin }
	| { type: "Question", content: QuestionMin }
	| { type: "Questionnaire", content: QuestionnaireMinMin };

export interface NotificationPayload {
	type_: NotificationType;
	actor?: UserId;
	account_id: AccountId;
	payload: NotificationPayloadType;
}

export interface Notification {
	notification_id: NotificationId;
	payload: NotificationPayload;
}

export interface NotificationChannel {
	notification_channel_id: NotificationChannelId;
	account_id: AccountId;
	name: string;
	config: NotificationChannelConfig;
	connector: NotificationChannelConnector;
	external_authorization?: ExternalAuthorization;
}

export interface NotificationChannelMinNEW {
	notification_channel_id: NotificationChannelIdNEW;
	channel_type: NotificationChannelTypeNEW;
}

export interface NotificationChannelNEW {
	notification_channel_id: NotificationChannelIdNEW;
	channel_type: NotificationChannelTypeNEW;
	config: NotificationChannelConfigNEW;
}

export enum NotificationChannelCategory {
	ConnectedApps = "ConnectedApps",
	Email = "Email",
	EmailDigest = "EmailDigest",
	InApp = "InApp",
}

export interface NotificationPreferencesConfig {
	notification_preferences: Record<NotificationChannelCategory, NotificationType[]>;
}

export interface NotificationState {
	notification_channel_id: NotificationChannelIdNEW;
	state: NotificationPayload[];
}

export interface NotificationsState {
	notifications: NotificationPayload[];
}

export interface NotionDocumentSourceConfig {
}

export interface OAuthLoginRequest {
	provider: OAuthProvider;
	access_token: string;
}

export interface OriginalFile {
	file: File;
	imported_at?: ApiDateTime;
}

export interface OriginalSource {
	url: string;
	imported_at?: ApiDateTime;
}

export interface Paginated<T> {
	items: T[];
	offset: number;
	limit: number;
	total: number;
}

export interface PasswordConfig {
	length_requirement?: number;
	unique: boolean;
	random: boolean;
	characters: CharacterConfig;
	expiry?: number;
}

export enum PasswordManager {
	OnePassword = "1Password",
	LastPass = "LastPass",
	Keeper = "Keeper",
}

export enum Sso {
	GSuite = "GSuite",
	Okta = "Okta",
	AzureAD = "AzureAD",
	None = "None",
}

export interface ToolsConfig {
	password_manager: PasswordManager;
	password_manager_instructions: string;
	sso: Sso;
	sso_instructions: string;
}

export interface PasswordPolicyConfig {
	tools: ToolsConfig;
	passwords: PasswordConfig;
	exceptions: ExceptionConfig;
}

export interface Property {
	property_name: string;
	value: IValue;
}

export type GraphPropertyValue = 
	| { type: "String", content: string }
	| { type: "Integer", content: number }
	| { type: "Boolean", content: boolean }
	| { type: "Asset", content: AssetId }
	| { type: "Relationship", content: GraphPropertyValueRelationship };

export interface PropertyComponent {
	entity_id: EntityId;
	property_name: string;
	value: GraphPropertyValue;
}

export interface QuestionCreatedPayload {
}

export interface QuestionMin {
	question_id: QuestionId;
	question_number?: string;
	text: string;
}

export interface QuestionSource {
	question_source_id: QuestionSourceId;
	name: string;
	connector: QuestionSourceConnector;
	config: QuestionSourceConfig;
	external_authorization: ExternalAuthorizationMin;
	external_account_id: string;
}

export interface QuestionSourceAccountInfo {
	external_account_id: string;
	external_name: string;
	external_icon_url: string;
}

export interface QuestionStats {
	count: number;
	in_review: number;
	completed: number;
}

export enum QuestionnaireStatus {
	Importing = "Importing",
	Automating = "Automating",
	InProgress = "InProgress",
	Complete = "Complete",
}

export interface Section {
	section_id: SectionId;
	title: string;
	description: string;
	questions: QuestionMin[];
}

export interface Questionnaire {
	questionnaire_id: QuestionnaireId;
	counterparty: Counterparty;
	deal?: Deal;
	name: string;
	owner?: Owner;
	due_date?: ApiDateTime;
	status: QuestionnaireStatus;
	archived_at?: ApiDateTime;
	questionnaire_date?: ApiDateTime;
	question_stats: QuestionStats;
	sections: Section[];
	original_files: OriginalFile[];
	original_sources: OriginalSource[];
	scope_matrix: ScopeMatrix;
	prompt_modifiers: PromptModifiers;
	is_internal: boolean;
	internal_language_code: string;
	external_language_code: string;
	trust_center_visibility: TrustCenterAccess;
	deleted?: Actioned;
}

export interface QuestionnaireMin {
	questionnaire_id: QuestionnaireId;
	counterparty: Counterparty;
	name: string;
	owner?: Owner;
	due_date?: ApiDateTime;
	status: QuestionnaireStatus;
	archived_at?: ApiDateTime;
	questionnaire_date?: ApiDateTime;
	question_stats: QuestionStats;
	scope_matrix: ScopeMatrix;
	prompt_modifiers: PromptModifiers;
	is_internal: boolean;
	internal_language_code: string;
	external_language_code: string;
	trust_center_visibility: TrustCenterAccess;
	deleted?: Actioned;
}

export interface QuestionnaireMinMin {
	questionnaire_id: QuestionnaireId;
	name: string;
	status: QuestionnaireStatus;
}

export interface QuestionnaireQuestionTask {
	questionnaire: QuestionnaireMin;
	question: Question;
}

export interface RelevantFact {
	fact: Fact;
	question_cosine_distance: number;
	answer_cosine_distance?: number;
}

export interface ReportedError {
	occurred_at: ApiDateTime;
	title: string;
	description: string;
	link_url?: string;
}

export interface RequestLoginReset {
	email: string;
	redirect?: string;
}

export interface RequestRegistration {
	name: string;
	email: string;
	redirect?: string;
}

export interface ResetLoginInfo {
	is_new_user: boolean;
	recommended_login_methods: LoginMethod[];
	requires_2fa: boolean;
}

export interface Resolution {
	resolution_id: ResolutionId;
	scope?: ScopeMin;
	resolution_type: ResolutionType;
	involved_facts: Fact[];
	reason: string;
	review_necessity: number;
	review_explanation: string;
	created_at: ApiDateTime;
	owner?: Owner;
	resolved?: Actioned;
	ignored?: Actioned;
	is_internal: boolean;
}

export interface ResponseLayer {
	response_yes_no?: boolean;
	response_select?: ResponseOption[];
	response_text?: string;
	status: QuestionStatus;
	due_date?: ApiDateTime;
	owner?: Owner;
	documentation_count: number;
	activity_stream: ActivityStream;
	ai_attempted: boolean;
	ai_review_comment?: string;
	ai_review_good?: boolean;
	linked_core_response?: CoreResponse;
}

export interface RichTextAttributes {
	bold?: boolean;
	italic?: boolean;
}

export interface RiskContext {
	data_room: DataRoomMin;
	risk_area: RiskArea;
}

export enum RiskLevel {
	FalsePositive = "FalsePositive",
	Low = "Low",
	Medium = "Medium",
	High = "High",
}

export interface RiskEvaluation {
	evaluated: Actioned;
	level: RiskLevel;
	reason: string;
}

export interface Risk {
	risk_id: RiskId;
	title: string;
	description: string;
	owner?: UserMin;
	contexts: RiskContext[];
	inherent_risk?: RiskEvaluation;
	residual_risk?: RiskEvaluation;
	relevant_controls?: Control[];
	accepted?: Actioned;
}

export interface Role {
	role_id: RoleId;
	system_role?: SystemRole;
	name: string;
	description: string;
	member_count: number;
}

export interface SARRequest {
	name?: string;
	company?: string;
	email: string;
	address?: string;
	phone_number?: string;
	content: string;
}

export interface SectionMin {
	section_id: SectionId;
	title: string;
	description: string;
}

export interface SelfEnrollmentDomain {
	domain: string;
}

export interface SendSupportEmail {
	action: string;
	subject: string;
	body: string;
	attachments: FileId[];
	cc_user: boolean;
	express?: boolean;
}

export interface SetPassword {
	prev_credentials?: LoginCredentials;
	new_password: string;
}

export interface ShowcaseMessageIds {
	message_ids: ShowcaseUpdateMessageId[];
	total: number;
}

export interface ShowcaseUpdateMessage {
	update_message_id: ShowcaseUpdateMessageId;
	title?: string;
	content?: string;
	sent_at?: ApiDateTime;
	updated_at: ApiDateTime;
	deleted_at?: ApiDateTime;
}

export interface SlackNotificationChannelConfig {
	team_id: string;
	team_name: string;
}

export interface SlackNotificationChannelConfigNew {
	team_id: string;
	team_name: string;
}

export interface SlackQuestionSourceConfig {
}

export type SourceKind = 
	| { type: "Document", content: DocumentSourceKind }
	| { type: "Questionnaire", content?: undefined };

export interface Source {
	source_id: SourceId;
	name: string;
	kind: SourceKind;
	created_at: ApiDateTime;
	analyzed_at?: ApiDateTime;
	deleted?: Actioned;
}

export type SourceMinKind = 
	| { type: "Document", content: DocumentSourceMinKind }
	| { type: "Questionnaire", content?: undefined };

export interface SourceMin {
	source_id: SourceId;
	name: string;
	kind: SourceMinKind;
	created_at: ApiDateTime;
	analyzed_at?: ApiDateTime;
	deleted?: Actioned;
}

export interface StandaloneFile {
	standalone_file_id: StandaloneFileId;
	file: File;
	sharing_classification: SharingClassification;
}

export interface SubGraph {
	root_entity_ids: EntityId[];
	entities: Record<EntityId, GraphEntity>;
}

export interface SubscribeRequest {
	email: string;
	metadata: SubscriberMetadata;
}

export interface Team {
	team_id: TeamId;
	name: string;
	description?: string;
	member_count: number;
	deleted_at?: ApiDateTime;
}

export interface TeamMin {
	team_id: TeamId;
	name: string;
}

export interface TextElement {
	attributes?: RichTextAttributes;
	text: string;
}

export interface ThirdParty {
	third_party_id: ThirdPartyId;
	name: string;
	tier: ThirdPartyTierMin;
	tags: ThirdPartyTagMin[];
	status: ThirdPartyStatusMin;
	business_owner?: Owner;
	risk_owner?: Owner;
	created_at: ApiDateTime;
	deleted?: Actioned;
	url: string;
	due_date?: ApiDateTime;
	score: number;
	linked_third_parties: ThirdPartyMin[];
}

export interface TrustCenterAiCheckItemConfig {
	title: string;
	description: string;
}

export interface TrustCenterRelatedDocumentConfig {
	document_id?: DocumentId;
}

export enum TrustCenterDataUseItemPii {
	None = "None",
	Pii = "Pii",
	SpecialCategory = "SpecialCategory",
}

export interface TrustCenterAiDataUseItemConfig {
	title: string;
	description: string;
	pii: TrustCenterDataUseItemPii;
	includes_customer_data: boolean;
	is_anonymized: boolean;
	used_for_training: boolean;
}

export interface TrustCenterAiModelProviderConfig {
	subprocessor_entity_id?: EntityId;
	models_used: string;
	purposes: string;
}

export interface TrustCenterAiConfig {
	enabled?: boolean;
	overview_content_id?: ContentId;
	related_documents?: TrustCenterRelatedDocumentConfig[];
	check_items?: TrustCenterAiCheckItemConfig[];
	data_use_items?: TrustCenterAiDataUseItemConfig[];
	model_providers?: TrustCenterAiModelProviderConfig[];
	faq_questionnaire_id?: QuestionnaireId;
}

export interface TrustCenterBusinessInfoContentConfig {
	title?: string;
	content_id?: ContentId;
}

export interface TrustCenterHeroConfig {
	heading?: string;
	subheading?: string;
}

export enum TrustCenterTrustedPartneredType {
	Trusted = "Trusted",
	Partnered = "Partnered",
}

export interface TrustCenterTrustedByConfig {
	logo_asset_id?: AssetId;
	logo_link_url?: string;
}

export interface TrustCenterOverviewConfig {
	welcome_id?: ContentId;
	content_id?: ContentId;
	trusted_partnered?: TrustCenterTrustedPartneredType;
	trusted_by?: TrustCenterTrustedByConfig[];
}

export interface TrustCenterDataProtectionDataUseItemConfig {
	title: string;
	description: string;
	pii: TrustCenterDataUseItemPii;
	includes_customer_data: boolean;
	is_anonymized: boolean;
}

export interface TrustCenterDataProtectionOperatingCountriesConfig {
	iso_country_code: string;
	detailed_address?: string;
	description?: string;
	gdpr_europe: boolean;
	gdpr_sccs: boolean;
	gdpr_equivalency: boolean;
}

export interface TrustCenterDataProtectionConfig {
	enabled?: boolean;
	overview_content_id?: ContentId;
	related_documents?: TrustCenterRelatedDocumentConfig[];
	data_use_items?: TrustCenterDataProtectionDataUseItemConfig[];
	operating_countries?: TrustCenterDataProtectionOperatingCountriesConfig[];
	subprocessors_enabled?: boolean;
	sar_enabled?: boolean;
}

export interface TrustCenterSubscribeConfig {
	enabled?: boolean;
}

export interface TrustCenterPasswordConfig {
	enabled?: boolean;
	password?: string;
}

export interface TrustCenterConfigMeta {
	name?: string;
	logo_asset_id?: AssetId;
	homepage_url?: string;
	favicon_asset_id?: AssetId;
	hero?: TrustCenterHeroConfig;
	overview?: TrustCenterOverviewConfig;
	business_info?: TrustCenterBusinessInfoContentConfig[];
	ai?: TrustCenterAiConfig;
	data_protection?: TrustCenterDataProtectionConfig;
	subscribe?: TrustCenterSubscribeConfig;
	privacy_policy?: string;
	password_protected?: TrustCenterPasswordConfig;
}

export interface TrustCenterConfig {
	document_request_email?: string;
	theme: IObject;
	meta: TrustCenterConfigMeta;
	is_public: boolean;
}

export interface TrustCenterConfigMin {
	theme: IObject;
	meta: TrustCenterConfigMeta;
	is_public: boolean;
}

export interface TrustCenterContent {
	content_id: ContentId;
	title: string;
	content: string;
}

export interface TrustCenterContentMin {
	content_id: ContentId;
	title: string;
}

export interface TrustCenterFaqQuestion {
	question_id: QuestionId;
	text: string;
	response: string;
}

export interface TrustCenterFaqSection {
	section_id: SectionId;
	title: string;
	questions: TrustCenterFaqQuestion[];
}

export interface TrustCenterFaq {
	sections: TrustCenterFaqSection[];
}

export interface TrustCenterSectionConfig {
	content_id?: ContentId;
}

export interface UnsubscribeRequest {
	RecordType: string;
	ServerID: number;
	Recipient: string;
}

export interface UpdateCrmConnection {
	external_authorization_id?: ExternalAuthorizationId;
	config: CrmConnectionConfig;
}

export interface UpdateDocumentContent {
	authority: DocumentAuthority;
	file_id?: FileId;
	markdown?: string;
	authoring_config?: Config;
	authoring_template?: string;
}

export interface UpdateDocumentSource {
	name: string;
	external_authorization_id?: ExternalAuthorizationId;
	config: DocumentSourceConfig;
	default_owner_id: OwnerId;
	default_sharing_classification: SharingClassification;
	default_limited_to_scope_ids?: ScopeId[];
}

export interface UpdateEsignatureProvider {
	external_authorization_id?: ExternalAuthorizationId;
	external_account_id?: string;
	config: EsignatureProviderConfig;
}

export interface UpdateLibrarySection {
	name?: string;
	description?: string;
}

export interface UpdateNotificationChannel {
	external_authorization_id?: ExternalAuthorizationId;
	config: NotificationChannelConfig;
}

export interface UpdateNotificationChannelNEW {
	config: NotificationChannelConfigNEW;
}

export interface UpdateNotificationPreference {
	notification_type: NotificationType;
	channel_category: NotificationChannelCategory;
	enabled: boolean;
}

export interface UpdateQuestionSource {
	external_authorization_id: ExternalAuthorizationId;
	config: QuestionSourceConfig;
}

export type EnableTwoFa = 
	| { type: "Totp", content: EnableTwoFaTotp };

export interface UpdateTwoFa {
	credentials: LoginCredentials;
	enable?: EnableTwoFa;
}

export interface UploadOptions {
	system_wide?: boolean;
}

export interface UsernamePasswordLoginRequest {
	email: string;
	remember_me: boolean;
	credentials: LoginCredentials;
}

export interface WebDocumentSourceConfig {
	urls: string[];
}

export interface WhoAmIResponse {
	user?: RegisteredUser;
	user_owner?: Owner;
	associated_owners: Owner[];
	account?: Account;
	apikey?: Apikey;
	knock_jwt?: string;
	multi_account: boolean;
	internal_mode: boolean;
	used_2fa: boolean;
	roles: RoleMin[];
}

export enum AiStatus {
	NotAttempted = "NotAttempted",
	NoAnswer = "NoAnswer",
	RejectedAnswer = "RejectedAnswer",
	AcceptedAnswer = "AcceptedAnswer",
}

export type CreateLoginMethod = 
	| { type: "UsernamePassword", content: SetPassword }
	| { type: "OAuth", content: string };

export type DocumentUpdatedPayload = 
	| { type: "Created", content?: undefined }
	| { type: "Name", content: string }
	| { type: "Category", content: DocumentCategory }
	| { type: "Scopes", content?: undefined }
	| { type: "SharingClassification", content: SharingClassification }
	| { type: "Owner", content?: undefined }
	| { type: "ReviewPeriod", content?: undefined }
	| { type: "TrustCenterConfig", content?: undefined }
	| { type: "File", content?: undefined }
	| { type: "AiText", content?: undefined }
	| { type: "Deleted", content?: undefined }
	| { type: "Restored", content?: undefined }
	| { type: "Generic", content?: undefined };

export enum ExternalAuthorizationType {
	Oauth = "Oauth",
	Raw = "Raw",
}

export type GraphOperation = 
	| { op: "CreateEntity", content?: undefined }
	| { op: "DeleteEntity", content: {
	entity_ref: GraphOperationEntityRef;
	recursive?: boolean;
}}
	| { op: "UpsertProperty", content: {
	name: string;
	entity_ref: GraphOperationEntityRef;
	value: GraphOperationPropertyValue;
}}
	| { op: "DeleteProperty", content: {
	name: string;
	entity_ref: GraphOperationEntityRef;
}};

export type GraphOperationPropertyValue = 
	| { type: "String", content: string }
	| { type: "Integer", content: number }
	| { type: "Boolean", content: boolean }
	| { type: "Asset", content: AssetId }
	| { type: "Relationship", content: GraphOperationPropertyValueRelationship };

export type GraphOperationResponse = 
	| { op: "CreateEntity", content: {
	entity_id: EntityId;
}}
	| { op: "DeleteEntity", content: {
	entity_ids: EntityId[];
}}
	| { op: "UpsertProperty", content: {
	existed: boolean;
}}
	| { op: "DeleteProperty", content: {
	existed: boolean;
}};

export type Invalidation = 
	| { type: "Question", content: QuestionId }
	| { type: "Questionnaire", content: QuestionnaireId };

export type LoginRequest = 
	| { type: "UsernamePassword", content: UsernamePasswordLoginRequest }
	| { type: "OAuth", content: OAuthLoginRequest };

export enum OAuthRedirectTarget {
	Google = "google",
	Atlassian = "atlassian",
	Hubspot = "hubspot",
	Slack = "slack",
	MicrosoftSpa = "microsoft-spa",
	MicrosoftWeb = "microsoft-web",
	Docusign = "docusign",
	Notion = "notion",
}

export enum OneTimeTokenType {
	Invite = "Invite",
	PasswordReset = "PasswordReset",
	SubscriptionVerification = "SubscriptionVerification",
}

export enum OwnerType {
	User = "User",
	Team = "Team",
}

export enum PovModifier {
	FirstPerson = "FirstPerson",
	ThirdPerson = "ThirdPerson",
}

export type PromptModifier = 
	| { type: "Verbosity", content: VerbosityModifier }
	| { type: "Pov", content: PovModifier }
	| { type: "Custom", content: string };

export enum PromptModifierType {
	Verbosity = "Verbosity",
	Pov = "Pov",
	Custom = "Custom",
}

export type QuestionUpdatedPayload = 
	| { type: "Text", content?: undefined }
	| { type: "Parts", content?: undefined }
	| { type: "DueDate", content?: ApiDateTime }
	| { type: "Owner", content?: undefined }
	| { type: "Status", content: QuestionStatus }
	| { type: "Response", content?: undefined }
	| { type: "Generic", content?: undefined };

export type ResolveOwner = 
	| { type: "Team", content: TeamId }
	| { type: "User", content: UserId };

export type SourcedFact = 
	| { type: "Fact", content: Fact }
	| { type: "FactText", content: FactTextMin };

export enum SubscriptionAction {
	Verification = "Verification",
	AlreadySubscribed = "AlreadySubscribed",
	Resubscribe = "Resubscribe",
	RateLimited = "RateLimited",
}

export type Task = 
	| { type: "QuestionnaireQuestion", content: QuestionnaireQuestionTask }
	| { type: "AdHocQuestion", content: AdHocQuestionTask };

export type UnauthorizedError = 
	| { type: "MissingAuthorization", content: string }
	| { type: "IncorrectEmailOrPassword", content?: undefined }
	| { type: "IncorrectPassword", content?: undefined }
	| { type: "Requires2FA", content: {
	totp: boolean;
}}
	| { type: "Failed2FA", content?: undefined }
	| { type: "IncorrectScope", content?: undefined }
	| { type: "PermissionDenied", content?: undefined };

export enum UpdateNotificationStatus {
	New = "New",
	Draft = "Draft",
	Sent = "Sent",
}

export enum VerbosityModifier {
	Descriptive = "Descriptive",
	Balanced = "Balanced",
	Concise = "Concise",
}

